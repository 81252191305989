import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Switch,
  Input,
  Form,
  Upload,
  Checkbox,
  Space,
} from "antd";

import BgProfile from "../assets/images/bg-profile.jpg";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDetails } from "../redux/usersList/userAction";
import {
  getConsultantbyid,
  updateConsultant,
} from "../redux/consultant/consultantAction";
import axios from "axios";
function Profile() {
  const { activeConsultant, loading } = useSelector(
    (state) => state.consultant
  );

  const [formData, setformData] = useState({
    phone: "",
    about: "",
    availability: "",
    pricePerMinute: "",
    communicationType: "",
    image: "",
    firstName: "",
    lastName: "",
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const successdata = useSelector((state) => state.consultant.consultantupdate);
  if (successdata.acknowledged == true) {
    navigate("/consultantprofile");
  }
  const dispatch = useDispatch();
  const {
    phone,
    about,
    availability,
    pricePerMinute,
    comunicationType,
    firstName,
    lastName,
    user,
  } = activeConsultant;
  console.log(formData);

  const s = () => {
    setformData({
      phone: phone,
      about: "",
      availability: "",
      pricePerMinute: "",
      communicationType: "",
      image: "",
      firstName: "",
      lastName: "",
    });
 
  };
  
  useEffect(() => {
    dispatch(getConsultantbyid(id));
  }, []);

  const handelchange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const blockhandelchange = (e) => {
    dispatch(updateUserDetails(activeConsultant?.user._id, { blocked: e }));
  };

  const [image, setimage] = useState();

  const handelsubmit = async (e) => {
    try {
      if (image.type === "image/jpeg" || image.type === "image/png") {
        const data = new FormData();
        data.append("file", image);
        data.append("upload_preset", "chatapp");
        data.append("cloud_name", "sourabhvaish");

        let res = await axios.post(
          "https://api.cloudinary.com/v1_1/sourabhvaish/image/upload",
          data
        );
        if (res?.data?.url) {
          formData.image = res?.data?.url;
          console.log(formData);

          dispatch(updateConsultant(id, formData));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(formData);
  return (
    <>
      {!loading && (
        <div>
          <div
            className="profile-nav-bg"
            style={{ backgroundImage: "url(" + BgProfile + ")" }}
          ></div>

          <Card
            className="card-profile-head"
            bodyStyle={{ display: "none" }}
            title={
              <Row justify="space-between" align="middle" gutter={[24, 0]}>
                <Col span={24} md={12} className="col-info">
                  <Avatar.Group>
                    <Avatar
                      size={74}
                      shape="square"
                      src={activeConsultant?.image}
                    />
                    <div className="" style={{ margin: "0 20px" }}>
                      {activeConsultant?.firstName} {activeConsultant?.lastName}
                    </div>
                  </Avatar.Group>
                </Col>
                <Col
                  span={24}
                  md={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                ></Col>
              </Row>
            }
          ></Card>
          <Form
            // onSubmit={handelsubmit}
            onFinish={handelsubmit}
          >
            <Row gutter={[24, 0]}>
              <Col span={24} md={8} className="">
                <Card
                  bordered={false}
                  className="header-solid "
                  title={
                    <h6 className="font-semibold m-0">Consultant Details</h6>
                  }
                >
                  <ul className="list settings-list">
                    <li>
                      <h6 className="list-header text-sm text-muted">
                        ACCOUNT
                      </h6>
                    </li>
                    <li>
                      {activeConsultant?.user?.blocked === true ? (
                        <Switch
                          name="block"
                          onChange={blockhandelchange}
                          defaultChecked
                        />
                      ) : (
                        <Switch onChange={blockhandelchange} />
                      )}

                      <span>Block Account</span>
                    </li>
                    <div className="" />

                    <Descriptions title="">
                      <Descriptions.Item label="Price Per Minute" span={3}>
                        <Input
                          name="pricePerMinute"
                          defaultValue={activeConsultant?.pricePerMinute}
                          type="number"
                          onChange={(e) => {
                            setformData({
                              ...formData,
                              ["pricePerMinute"]: Number(e.target.value),
                            });
                          }}
                        ></Input>
                      </Descriptions.Item>

                      <Descriptions.Item label="Comunication Type" span={3}>
                        <Checkbox
                          value="audio"
                          defaultChecked={
                            activeConsultant?.communicationType?.includes(
                              "audio"
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            // console.log(e.target);
                            let arr = [...formData.communicationType];
                            var myIndex = arr.indexOf(e.target.value);
                            if (e.target.checked) {
                              arr.push(e.target.value);
                              setformData({
                                ...formData,
                                communicationType: arr,
                              });
                            } else {
                              arr.splice(myIndex, 1);
                              setformData({
                                ...formData,
                                communicationType: arr,
                              });
                            }
                          }}
                        >
                          Audio
                        </Checkbox>
                        <Checkbox
                          value="video"
                          defaultChecked={
                            activeConsultant?.communicationType?.includes(
                              "video"
                            )
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            let arr = [...formData.communicationType];
                            var myIndex = arr.indexOf(e.target.value);
                            if (e.target.checked) {
                              arr.push(e.target.value);
                              setformData({
                                ...formData,
                                communicationType: arr,
                              });
                            } else {
                              arr.splice(myIndex, 1);
                              setformData({
                                ...formData,
                                communicationType: arr,
                              });
                            }
                          }}
                        >
                          video
                        </Checkbox>
                      </Descriptions.Item>
                      <Descriptions.Item label="Image" span={3}>
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                          }}
                          size="large"
                        >
                          <input
                            type="file"
                            onChange={(e) => {
                              // imagechnage(e.target.files[0]);
                              setimage(e.target.files[0]);
                            }}
                          />
                        </Space>
                      </Descriptions.Item>
                    </Descriptions>
                  </ul>
                </Card>
              </Col>
              <Col span={24} md={8} className="mb-24">
                <Card bordered={false}>
                  <Descriptions title="Details">
                    <Descriptions.Item label="FirstName" span={3}>
                      <Input
                        defaultValue={activeConsultant?.firstName}
                        name="firstName"
                        onChange={handelchange}
                      ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="LastName" span={3}>
                      <Input
                        defaultValue={activeConsultant?.lastName}
                        name="lastName"
                        onChange={handelchange}
                      ></Input>
                    </Descriptions.Item>

                    <Descriptions.Item label="About" span={3}>
                      <Input
                        defaultValue={activeConsultant?.about}
                        name="about"
                        onChange={handelchange}
                        style={{ width: "390px", margin: "0px 0px 0px 25px" }}
                      ></Input>
                    </Descriptions.Item>

                    <Descriptions.Item label="Availability" span={3}>
                      <Input
                        defaultValue={activeConsultant?.availability}
                        name="availability"
                        onChange={handelchange}
                      ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone" span={3}>
                      <Input
                        name="phone"
                        // accept="number"
                        // type=""
                        defaultValue={activeConsultant?.phone}
                        style={{ width: "390px", margin: "0px 0px 0px 25px" }}
                        id="phone"
                        type="number"
                        //  value={formData?.phone}
                        onChange={(e) => {
                          setformData({
                            ...formData,
                            ["phone"]: e.target.value,
                          });
                        }}
                      ></Input>
                    </Descriptions.Item>
                  </Descriptions>
                  <Button
                    // type="primary"
                    type="primary"
                    htmlType="submit"
                    className="tag-primary"
                    style={{ background: "#A40165", borderColor: "#A40165" }}
                  >
                    Submit
                  </Button>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
}

export default Profile;
