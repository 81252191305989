import React, { useEffect, useRef, useState } from "react";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import { Row, Col } from "antd/lib/grid";
import { Widget, addResponseMessage, toggleWidget } from "react-chat-widget";
import "./style.css";
import "react-chat-widget/lib/styles.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  consultantmsgclearlength,
  getconsultatMessageByroomId,
  getUserList,
  newmsgreciveconsultant,
  newmsgreciveconsultantclear,
  newmsgreciveconsultantclient,
} from "../redux/usersList/userAction";
import {
  getUserAnalytics,
  getUserProfileAnalytics,
} from "../redux/analytics/AnalytisAction";
import Chatcard from "../components/Chatcard";
import { socketsss } from "../components/layout/consultantSidenav";
import {
  sendConsultantMessage,
  sendPushNotification,
} from "../redux/consultant/consultantAction";
import audio from "../components/chart/Audio/AppleNotification.mp3";
const ConsultantChatbox = () => {
  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
  };
  const datas = useSelector((state) => state.user.userList);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("Consultantprofile"));
  // console.log(userData);
  const [isconsultant, setisconsultant] = useState(false);
  const ConsultantProfileData = JSON.parse(
    localStorage.getItem("Consultantprofile")
  );
  useEffect(() => {
    socketsss.emit("join-room", ConsultantProfileData._id);

    toggleWidget();
    dispatch(getUserList());
    dispatch(getUserProfileAnalytics());
    dispatch(getUserAnalytics());
    addResponseMessage("Welcome to this awesome chat!");
  }, []);
  // useEffect(() => {

  //   return () => {

  //     // dispatch(getUserList());
  //   }
  // }, [])
  const ind = localStorage.getItem("index");
  const [changeChat, setchangeChat] = useState({});
  const [message, setmessage] = useState("");
  const [activechat, setactivechat] = useState({});
  const [index, setindex] = useState(Number(ind));
  const [lastindex, setlastindex] = useState("");
  // useEffect(() => {
  //   console.log({ changeChat });
  //   if (changeChat._id) {
  //   }

  //   return () => {};
  // }, [changeChat]);
  const [messages, setmessages] = useState([]);

  // console.log({ messages });
  const chatmessage = useSelector((state) => state.user?.consultantChat);
  // console.log({ datas });
  useEffect(() => {
    //  if (incmsg == false) {
    // }
    // dispatch(getUserList());
    dispatch(getconsultatMessageByroomId(userData?._id + datas[index]?._id));

    // console.log(datas[index]);
    return () => {};
  }, [datas]);
  useEffect(() => {
    dispatch(getconsultatMessageByroomId(userData?._id + datas[index]?._id));
    // dispatch(getUserList());
    dispatch(newmsgreciveconsultantclear(datas[index]?._id));
    dispatch(consultantmsgclearlength(datas[index]?._id));

    console.log("exit", datas[index]?._id);
    // dispatch(getUserList());

    return () => {};
  }, [index]);

  async function sendMessageNotification(data) {
    const message = {
      to: data?.expoPushToken,
      sound: "default",
      title: data?.firstName,
      body: data?.message,
      data: { someData: "message" },
    };

    await fetch("https://exp.host/--/api/v2/push/send", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Accept-encoding": "gzip, deflate",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    });
  }
  const consultantProfile = JSON.parse(
    localStorage.getItem("Consultantprofile")
  );

  const [activechats, setactivechats] = useState("");
  const [incmsg, setincmsg] = useState(false);
  useEffect(() => {
    setmessages([]);
    setincmsg(false);
    setactivechats(userData?._id + datas[index]?._id);
    // dispatch(getUserList())
    return () => {};
  }, [index]);
  useEffect(() => {
    scrollToBottom();

    return () => {};
  }, [chatmessage]);

  // console.log();
  // console.log({ mmm: datas[index] });
  // useEffect(() => {
  //   return () => {};
  // }, [datas]);
  const a = new Audio(audio);
  useEffect(() => {
    socketsss.on("message", (data) => {
      console.log({ data });
      // a.play()
      setincmsg(true);
      setisconsultant(false);
      dispatch(newmsgreciveconsultant(data));
      setmessages((oldArray) => [...oldArray, data]);
      scrollToBottom();
      // dispatch(getconsultatMessageByroomId(data.roomid));
      // setlastmessageactive(data);
      // addResponseMessage(data.data);
      // if (first == true) {
      // }
    });
    return () => {
      socketsss.off("message");
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const messagesEndRef = useRef(null);
  // console.log({ ppp: datas[index]?._id });
  // console.log(lastindex);
  const handelsubmit = () => {
    // if (datas[index]?.expoPushToken !== null) {
    //   sendMessageNotification({
    //     expoPushToken: datas[index]?.expoPushToken,
    //     firstName: "consultant",
    //     message: message.trim(),
    //     senderId: userData?._id, //profile id
    //   });
    // }

    // console.log(userData._id + datas[index]._id);
    // return;
    if (!message) {
      return;
    }
    setincmsg(true);
    setisconsultant(true);

    setmessages((oldArray) => [
      ...oldArray,
      {
        data: message,
        roomid: userData._id + datas[index]._id,
        userid: userData._id,
      },
    ]);

    if (datas[index].expoPushToken !== null) {
      dispatch(
        sendPushNotification({
          expoPushToken: datas[index].expoPushToken,
          firstName: `Consultant: ${consultantProfile?.firstName}`,
          message: message,
          someData: "Consultant",
        })
      );
    }
    dispatch(
      newmsgreciveconsultantclient({
        data: {
          data: message,
          roomid: userData._id + datas[index]._id,
          userid: userData._id,
        },
        id: datas[index]._id,
      })
    );

    // console.log(message, datas[index]._id);
    socketsss.emit("message", {
      data: message.trim(),
      roomid: userData._id + datas[index]._id,
      userid: userData._id,
    });
    sendConsultantMessage({
      data: message,
      roomid: userData._id + datas[index]._id,
      userid: userData._id,
    }).then((e) => {
      scrollToBottom();
    });

    setmessage("");
  };
  const handleKeyPress = (e) => {
    // Check if the Enter key (key code 13) was pressed
    if (e.key === "Enter") {
      // Trigger the button click event
      handelsubmit();
    }
  };

  return (
    <div className="container">
      <div className="leftsidebar">
        {datas.map((e, index) => {
          return (
            // console.log(userData._id + e._id),
            socketsss.emit("join-room", userData._id + e._id),
            (
              <Chatcard
                last={datas[index]}
                setlastindex={setlastindex}
                isconsultant={isconsultant}
                ind={ind}
                index={index}
                setindex={setindex}
                e={e}
              />
            )
          );
        })}
      </div>
      <div className="rightsidebar">
        <div class="card">
          {chatmessage.length > 0 && (
            <div class="card-body">
              {chatmessage.map((e) => {
                // console.log(e?.userid,userData?._id);

                return e?.userid !== userData?._id ? (
                  <div class="text-left">
                    <div className="name">
                      <span class="text" title="2020-01-01 09:00:00">
                        {e?.data}
                      </span>
                      <div className="title">{datas[index].firstName}</div>
                    </div>
                  </div>
                ) : (
                  <div class="text-right">
                    <div className="s">
                      <span class="text1" title="2020-01-01 09:00:00">
                        {e?.data}
                      </span>
                      <div className="title">You</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {datas[index]?.chatmsg.length > 0 && incmsg && (
            <div class="card-body">
              {datas[index]?.chatmsg.map((e) => {
                // console.log({e});
                return e?.userid !== userData?._id ? (
                  <div class="text-left">
                    <div className="name">
                      <span class="text" title="2020-01-01 09:00:00">
                        {e?.data}
                      </span>
                      <div className="title">{datas[index].firstName}</div>
                    </div>
                  </div>
                ) : (
                  <div class="text-right">
                    <div className="s">
                      <span class="text1" title="2020-01-01 09:00:00">
                        {e?.data}
                      </span>
                      <div className="title">You</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="chatinput">
          <input
            value={message}
            onChange={(e) => {
              setmessage(e.target.value);
            }}
            onKeyUp={handleKeyPress}
            className="chatinp"
            type="text"
          />
          <button onClick={handelsubmit} className="chatsubmitbutton">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsultantChatbox;
