import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userLoginReducer } from "./redux/adminLogin/adminReducer";
import { userReducer } from "./redux/usersList/userReducer";
import { analyticsReducer } from "./redux/analytics/AnalyticsReducer";
import { consultantReducers } from "./redux/consultant/consultantReducer";
const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  user: userReducer,
  analytics: analyticsReducer,
  consultant:consultantReducers
});

const intialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  intialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
