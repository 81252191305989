import axios from "axios";
// export const url = "http://3.111.47.47:5000";
// export const url = "http://localhost:5000";

export const url = "https://backend.wannabae.in";

export default axios.create({
  baseURL: url,

  // baseURL: "https://lexpedia-rest-api.herokuapp.com/api/v1",
});

export const SOCKET_URL = url;

// baseURL: "http://3.110.217.18:5000"