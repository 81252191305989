import {
  GET_USERLIST_REQUEST,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_FAIL,
  GET_USERDEATILS_REQUEST,
  GET_USERDEATILS_SUCCESS,
  GET_USERDEATILS_FAIL,
  GET_USERUPDATE_REQUEST,
  GET_USERUPDATE_SUCCESS,
  GET_USERUPDATE_FAIL,
  CONSULTANT_MESSAGE_REQUEST,
  CONSULTANT_MESSAGE_SUCCESS,
  CONSULTANT_MESSAGE_FAIL,
  CONSULTANT_NEW_MESSAGE,
  CONSULTANT_NEW_MESSAGE_CLIENT,
  CONSULTANT_NEW_MESSAGE_CLEAR,
  CONSULTANT_MSG_CLEAR_LENGTH
} from "./userConstant";
import api from "../../utils/api";

// export const socket = socketIo(SOCKET_URL, { transports: ["websocket"] });

export const getUserList = () => async (dispatch) => {
  try {
    dispatch({ type: GET_USERLIST_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    const { data } = await api.get("/profile", config);
    const b = data.map((obj) => ({ ...obj, chatmsg: [], chatlength: [] }));
    dispatch({ type: GET_USERLIST_SUCCESS, payload: b });
  } catch (error) {
    dispatch({
      type: GET_USERLIST_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createUserforAdmin = async (formData) => {
  try {
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    const { data } = await api.post("/user/createuseradmin", formData, config);
    console.log(data);
    return data;
  } catch (error) {
    return error;
  }
};

export const getUserDeatils = (profileid) => async (dispatch) => {
  try {
    dispatch({ type: GET_USERDEATILS_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    const data = await api.get(`/profile/${profileid}`, config);
    console.log(data);
    dispatch({ type: GET_USERDEATILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_USERDEATILS_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserDetails = (userid, userdata) => async (dispatch) => {
  try {
    dispatch({ type: GET_USERUPDATE_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    const { data } = await api.put(`/user/${userid}`, userdata, config);

    dispatch({ type: GET_USERUPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_USERUPDATE_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserDeatilsbyId = (profileid) => async (dispatch) => {
  try {
    dispatch({ type: GET_USERDEATILS_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    const data = await api.get(`/user/${profileid}`, config);
    console.log(data);
    dispatch({ type: GET_USERDEATILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_USERDEATILS_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserLists = () => async (dispatch) => {
  try {
    dispatch({ type: GET_USERLIST_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    const { data } = await api.get("/user", config);
    console.log(data);
    dispatch({ type: GET_USERLIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_USERLIST_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getconsultatMessageByroomId =
  (roomid) => async (dispatch, getState) => {
    const token = localStorage.getItem("authorization");

    const state = getState();
    const {
      user: { access_token },
    } = state;

    dispatch({ type: CONSULTANT_MESSAGE_REQUEST });

    const config = {
      headers: {
        Authorization: JSON.parse(token),
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await api.get(`/consultantmessage/${roomid}`, config);
      // console.log({ data });
      dispatch({ type: CONSULTANT_MESSAGE_SUCCESS, payload: data });
    } catch (error) {
      console.log(error);
      dispatch({
        type: CONSULTANT_MESSAGE_FAIL,
        payload: {},
      });
    }
  };

export const newmsgreciveconsultant = (data) => async (dispatch, getState) => {
  dispatch({ type: CONSULTANT_NEW_MESSAGE, payload: data });
};

export const newmsgreciveconsultantclient =
  (data) => async (dispatch, getState) => {
    // console.log(index);
    dispatch({ type: CONSULTANT_NEW_MESSAGE_CLIENT, payload: data });
  };

export const newmsgreciveconsultantclear =
  (id) => async (dispatch, getState) => {
    // console.log(index);
    console.log("hhh");
    console.log({ id });
    dispatch({ type: CONSULTANT_NEW_MESSAGE_CLEAR, payload: id });
  };


  
export const consultantmsgclearlength =
  (id) => async (dispatch, getState) => {
    // console.log(index);
    console.log("hhh");
    console.log({ id });
    dispatch({ type: CONSULTANT_MSG_CLEAR_LENGTH, payload: id });
  };
