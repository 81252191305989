import {
  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAIL,
  GET_USERANALYTICS_REQUEST,
  GET_USERANALYTICS_SUCCESS,
  GET_USERANALYTICS_FAIL,

  GET_USERPROFILEANALYTICS_REQUEST,
  GET_USERPROFILEANALYTICS_SUCCESS,
  GET_USERPROFILEANALYTICS_FAIL,
} from "./AnalyticsConstant";
const initialState = {
  loading: false,
  analyticsData: [],
  UseranalyticsData: [],
  userProfileAnalytics: [],
  error: null,
};

export const analyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsData: payload,
        loading: false,
      };

    case GET_ANALYTICS_FAIL:
      return {
        ...state,
        loading: false,
        analyticsData: [],
      };
    case GET_USERANALYTICS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_USERANALYTICS_SUCCESS:
      return {
        ...state,
        UseranalyticsData: payload,
        loading: false,
      };

    case GET_USERANALYTICS_FAIL:
      return {
        ...state,
        loading: false,
        UseranalyticsData: [],
      };

      case GET_USERPROFILEANALYTICS_REQUEST:
        return {
          ...state,
          loading: true,
        };
  
      case GET_USERPROFILEANALYTICS_SUCCESS:
        return {
          ...state,
          userProfileAnalytics: payload,
          loading: false,
        };
  
      case GET_USERPROFILEANALYTICS_FAIL:
        return {
          ...state,
          loading: false,
          userProfileAnalytics: [],
        };
    default:
      return state;
  }
};
