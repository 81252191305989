import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { socketsss } from "../components/layout/consultantSidenav";
import ringtone from "../assets/callaudio.mp3";
import "./s.css";
import { useDispatch } from "react-redux";
import { ClearincomingCall } from "../redux/consultant/consultantAction";
import { useSelector } from "react-redux";
var audio = new Audio(ringtone);
const Incomingcall = (props) => {
  const dispatch = useDispatch();

  const params = useParams();
  const navigate = useNavigate();
  console.log({ params: params.id });
  const handelRejectCall = () => {
    socketsss.emit("endcall", {
      toUser: params.id,
      fromUser: ConsultantProfileData._id,
    });
    setTimeout(() => {
      dispatch(ClearincomingCall());
      navigate("/users");
    }, 1000);
  };
  const ConsultantProfileData = JSON.parse(
    localStorage.getItem("Consultantprofile")
  );
  const handelAccpetCall = () => {
    console.log("object");
    socketsss.emit("accpetcall", {
      toUser: params.id,
      fromUser: ConsultantProfileData._id,
    });
    navigate(`/oncall/${params.id}/${params.meetingid}`);
  };
  const datas = useSelector((state) => state?.consultant?.incomingCall);

  useEffect(() => {
    if (!datas) {
      navigate("/users");
    }

    return () => {};
  }, [datas]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "aqua",
        height: "100%",
        flexDirection: "column",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin:"20px 0px"
        }}>
        <img
          style={{ borderRadius: "50%" }}
          width={100}
          height={100}
          src={datas?.profilePic}
        />
        <h4>{datas?.name}</h4>
        <div>incoming call</div>
      </div>
      <div>
        <button
          id="rejectButton"
          // style={{ backgroundColor: "#FF5733", color: "white" }}
          onClick={handelRejectCall}>
          End Call
        </button>
        <button
          id="acceptButton"
          // style={{ backgroundColor: "#4CAF50", color: "white" }}
          onClick={handelAccpetCall}>
          Accept Call
        </button>
      </div>
    </div>
  );
};

export default Incomingcall;
