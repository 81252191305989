export const GET_CONSULTANTLIST_REQUEST = "GET_CONSULTANTLIST_REQUEST";
export const GET_CONSULTANTLIST_SUCCESS = "GET_CONSULTANTLIST_SUCCESS";
export const GET_CONSULTANTLIST_FAIL = "GET_CONSULTANTLIST_FAIL ";

export const GET_CONSULTANTDEATILS_REQUEST = "GET_CONSULTANTDEATILS_REQUEST";
export const GET_CONSULTANTDEATILS_SUCCESS = "GET_CONSULTANTDEATILS_SUCCESS";
export const GET_CONSULTANTDEATILS_FAIL = "GET_CONSULTANTDEATILS_FAIL ";

export const GET_CONSULTANTUPDATE_REQUEST = "GET_CONSULTANTUPDATE_REQUEST";
export const GET_CONSULTANTUPDATE_SUCCESS = "GET_CONSULTANTUPDATE_SUCCESS";
export const GET_CONSULTANTUPDATE_FAIL = "GET_CONSULTANTUPDATE_FAIL ";

export const GET_CONSULTANTCREATE_REQUEST = "GET_CONSULTANTCREATE_REQUEST";
export const GET_CONSULTANTCREATE_SUCCESS = "GET_CONSULTANTCREATE_SUCCESS";
export const GET_CONSULTANTCREATE_FAIL = "GET_CONSULTANTCREATE_FAIL ";



export const INCOMING_CALL = "INCOMING_CALL ";

export const CLEAR_INCOMING_CALL = "CLEAR_INCOMING_CALL ";
