export const GET_ANALYTICS_REQUEST = "GET_ANALYTICS_REQUEST";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_FAIL = "GET_ANALYTICS_FAIL ";


export const GET_USERANALYTICS_REQUEST = "GET_USERANALYTICS_REQUEST";
export const GET_USERANALYTICS_SUCCESS = "GET_USERANALYTICS_SUCCESS";
export const GET_USERANALYTICS_FAIL = "GET_USERANALYTICS_FAIL ";



export const GET_USERPROFILEANALYTICS_REQUEST = "GET_USERPROFILEANALYTICS_REQUEST";
export const GET_USERPROFILEANALYTICS_SUCCESS = "GET_USERPROFILEANALYTICS_SUCCESS";
export const GET_USERPROFILEANALYTICS_FAIL = "GET_USERPROFILEANALYTICS_FAIL ";