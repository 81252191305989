export const GET_USERLIST_REQUEST = "GET_USERLIST_REQUEST";
export const GET_USERLIST_SUCCESS = "GET_USERLIST_SUCCESS";
export const GET_USERLIST_FAIL = "GET_USERLIST_FAIL ";

export const GET_USERDEATILS_REQUEST = "GET_USERDEATILS_REQUEST";
export const GET_USERDEATILS_SUCCESS = "GET_USERDEATILS_SUCCESS";
export const GET_USERDEATILS_FAIL = "GET_USERDEATILS_FAIL ";

export const GET_USERUPDATE_REQUEST = "GET_USERUPDATE_REQUEST";
export const GET_USERUPDATE_SUCCESS = "GET_USERUPDATE_SUCCESS";
export const GET_USERUPDATE_FAIL = "GET_USERUPDATE_FAIL ";

export const CONSULTANT_MESSAGE_REQUEST = "CONSULTANT_MESSAGE_REQUEST";
export const CONSULTANT_MESSAGE_SUCCESS = "CONSULTANT_MESSAGE_SUCCESS";
export const CONSULTANT_MESSAGE_FAIL = "CONSULTANT_MESSAGE_FAIL";

export const CONSULTANT_NEW_MESSAGE = "CONSULTANT_NEW_MESSAGE";
export const CONSULTANT_NEW_MESSAGE_CLIENT = "CONSULTANT_NEW_MESSAGE_CLIENT";


export const CONSULTANT_NEW_MESSAGE_CLEAR = "CONSULTANT_NEW_MESSAGE_CLEAR";


export const CONSULTANT_MSG_CLEAR_LENGTH = "CONSULTANT_MSG_CLEAR_LENGTH";
