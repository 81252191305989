import { useEffect } from "react";

import { Row, Col, Card, Button, Descriptions, Avatar, Switch } from "antd";

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";

import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDeatils,
  updateUserDetails,
} from "../redux/usersList/userAction";
function Profile() {
  const { activeUser, loading } = useSelector((state) => state.user);

  const pencil = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"
        className="fill-gray-7"
      ></path>
      <path
        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"
        className="fill-gray-7"
      ></path>
    </svg>,
  ];

  const project = [
    {
      img: project1,
      titlesub: "Project #1",
      title: "Modern",
      disciption:
        "As Uber works through a huge amount of internal management turmoil.",
    },
    {
      img: project2,
      titlesub: "Project #2",
      title: "Scandinavian",
      disciption:
        "Music is something that every person has his or her own specific opinion about.",
    },
    {
      img: project3,
      titlesub: "Project #3",
      title: "Minimalist",
      disciption:
        "Different people have different taste, and various types of music, Zimbali Resort",
    },
  ];
  const { id } = useParams();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getUserDeatils(id));
    // return () => {};
  }, []);

  const blockhandelchange = (e) => {
    dispatch(updateUserDetails(activeUser?.data?.user?._id, { blocked: e }));
    console.log("change", e);
  };


  return (
    <>
      {!loading && (
        <div>
          <div
            className="profile-nav-bg"
            style={{ backgroundImage: "url(" + BgProfile + ")" }}
          ></div>

          <Card
            className="card-profile-head"
            bodyStyle={{ display: "none" }}
            title={
              <Row justify="space-between" align="middle" gutter={[24, 0]}>
                <Col span={24} md={12} className="col-info">
                  <Avatar.Group>
                    {/* <Avatar
                      size={74}
                      shape="square"
                      src={activeUser?.data?.profilePic}
                    /> */}

                    <div className="avatar-info">
                      <h4 className="font-semibold m-0">{activeUser?.name}</h4>
                      <p>{activeUser?.data?.user?.role}</p>
                      {activeUser?.data?.firstName} {activeUser?.data?.lastName}
                    </div>
                  </Avatar.Group>
                </Col>
                <Col
                  span={24}
                  md={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                ></Col>
              </Row>
            }
          ></Card>

          <Row gutter={[24, 0]}>
            <Col span={24} md={8} className="mb-24 ">
              <Card
                bordered={false}
                className="header-solid "
                title={<h6 className="font-semibold m-0">Platform Settings</h6>}
              >
                <ul className="list settings-list">
                  <li>
                    <h6 className="list-header text-sm text-muted">ACCOUNT</h6>
                  </li>
                  <li>
                    {activeUser?.data?.user?.blocked === true ? (
                      <Switch
                        name="block"
                        onChange={blockhandelchange}
                        defaultChecked
                      />
                    ) : (
                      <Switch onChange={blockhandelchange} />
                    )}

                    <span>Block Account</span>
                  </li>
                  {/* <li>
                    {activeUser?.role === "subscribe" ? (
                      <Switch
                        name="role"
                        onChange={rolehandelchnage}
                        defaultChecked
                      />
                    ) : (
                      <Switch onChange={rolehandelchnage} />
                    )}
                    <span> Subscription</span>
                  </li> */}
                  <div className="my-25" />

                  <Descriptions title="">
                    <Descriptions.Item label="Company" span={3}>
                      {activeUser?.data?.company}{" "}
                      {/* {activeUser?.data?.lastName} */}
                    </Descriptions.Item>
                    <Descriptions.Item label="Job Title" span={3}>
                      {activeUser?.data?.jobTitle}
                    </Descriptions.Item>
                    <Descriptions.Item label="School" span={3}>
                      {activeUser?.data?.school}
                    </Descriptions.Item>
                    <Descriptions.Item label="Offers" span={3}>
                      {activeUser?.data?.offers}
                    </Descriptions.Item>
                  </Descriptions>
                </ul>
              </Card>
            </Col>
            <Col span={24} md={8} className="mb-24">
              <Card
                bordered={false}
                title={
                  <h6 className="font-semibold m-0">Profile Information</h6>
                }
                className="header-solid h-full card-profile-information"
                extra={<Button type="link">{pencil}</Button>}
                bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <p className="text-dark">{activeUser?.data?.about}</p>
                <hr className="my-25" />
                <Descriptions title="Details">
                  <Descriptions.Item label="Full Name" span={3}>
                    {activeUser?.data?.firstName} {activeUser?.data?.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label="Gender" span={3}>
                    {activeUser?.data?.gender}
                  </Descriptions.Item>
                  <Descriptions.Item label="Mobile" span={3}>
                    {activeUser?.data?.user?.phone}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email" span={3}>
                    {activeUser?.data?.user?.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date of Birth" span={3}>
                    {activeUser?.data?.dob}
                  </Descriptions.Item>
                  <Descriptions.Item label="Height" span={3}>
                    {activeUser?.data?.height}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ctiy" span={3}>
                    {activeUser?.data?.city}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Col>
          </Row>
          {/* <Card
            bordered={false}
            className="header-solid mb-24"
            title={
              <>
                <h6 className="font-semibold">Projects</h6>
                <p>Architects design houses</p>
              </>
            }
          >
            <Row gutter={[24, 24]}>
              {project.map((p, index) => (
                <Col span={24} md={12} xl={6} key={index}>
                  <Card
                    bordered={false}
                    className="card-project"
                    cover={<img alt="example" src={p.img} />}
                  >
                    <div className="card-tag">{p.titlesub}</div>
                    <h5>{p.titile}</h5>
                    <p>{p.disciption}</p>
                    <Row gutter={[6, 0]} className="card-footer">
                      <Col span={12}>
                        <Button type="button">VIEW PROJECT</Button>
                      </Col>
                      <Col span={12} className="text-right">
                        <Avatar.Group className="avatar-chips">
                          <Avatar size="small" src={profilavatar} />
                          <Avatar size="small" src={convesionImg} />
                          <Avatar size="small" src={convesionImg2} />
                          <Avatar size="small" src={convesionImg3} />
                        </Avatar.Group>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card> */}
        </div>
      )}
    </>
  );
}

export default Profile;
