import {
  GET_ADMINLOGIN_REQUEST,
  GET_ADMINLOGIN_SUCCESS,
  GET_ADMINLOGIN_FAIL,
  GET_REFERAL_CODE_FAIL,
  GET_REFERAL_CODE_SUCCESS,
  GET_REFERAL_CODE_REQUEST,
} from "./adminConstant";

const initialState = {
  loading: false,
  users: [],
  referalCode: [],
};
export const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMINLOGIN_REQUEST:
      return { loading: true, users: [] };

    case GET_ADMINLOGIN_SUCCESS:
      return { loading: false, users: action.payload };

    case GET_ADMINLOGIN_FAIL:
      return { loading: false, error: action.payload };

    case GET_REFERAL_CODE_REQUEST:
      return { loading: true, referalCode: [] };

    case GET_REFERAL_CODE_SUCCESS:
      return { loading: false, referalCode: action.payload };

    case GET_REFERAL_CODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
