import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Switch,
  Upload,
  Form,
  Checkbox,
  Space,
  Select,
} from "antd";
import Swal from "sweetalert";
import { data } from "../components/consultantCategory";
import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import { Input } from "antd";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDeatilsbyId } from "../redux/usersList/userAction";
import {
  getUserDeatils,
  updateUserDetails,
} from "../redux/usersList/userAction";
import {
  createConsultant,
  createConsultantnoRedux,
} from "../redux/consultant/consultantAction";
import { InfoOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { Option } from "antd/lib/mentions";

function Profile(props) {
  const { activeUser, loading } = useSelector((state) => state.user);
  const cretedform = useSelector(
    (state) => state.consultant?.consultantCreated?.statusText
  );
  const navigate = useNavigate();
  if (cretedform == "Created") {
    navigate("/consultant");
  }

  const user = useSelector((state) => state.user?.activeUser?.data);
  // console.log("user", user);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDeatilsbyId(id));
    // dispatch(createConsultant());

    // return () => {};
  }, []);
  const [formData, setformData] = useState({
    id: id,
    phone: "",
    about: "",
    availability: "",
    pricePerMinute: "",
    communicationType: [],
    image: "",
    firstName: "",
    lastName: "",
    category: "",
  });

  // console.log(formData);

  const handelchange = (e) => {
    const isInteger = /^[0-9]+$/;
    if (e.target.value === "" || isInteger.test(e.target.value)) {
      // setformData({ ...formData, [e.target.name]: e.target.value })
      setformData({
        ...formData,
        ["phone"]: e.target.value,
      });
    }
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  const [notanumber, setnotanumber] = useState(false);
  const handelchangephone = (e) => {
    const isInteger = /^[0-9]+$/;
    if (e.target.value === "" || isInteger.test(e.target.value)) {
      // setformData({ ...formData, [e.target.name]: e.target.value })
      setformData({
        ...formData,
        ["phone"]: e.target.value,
      });
      setnotanumber(false);
    } else {
      console.log("text");
      setnotanumber(true);
    }
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setformData({
      ...formData,
      ["category"]: value,
    });
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  const blockhandelchange = (e) => {
    dispatch(updateUserDetails(id, { blocked: e }));
  };

  const { phone, about, availability, pricePerMinute, comunicationType } =
    formData;

  const [image, setimage] = useState();

  const imagechnage = (e) => {};
  const handelsubmit = async (e) => {
    try {
      if (image.type === "image/jpeg" || image.type === "image/png") {
        const data = new FormData();
        data.append("file", image);
        data.append("upload_preset", "chatapp");
        data.append("cloud_name", "sourabhvaish");

        let res = await axios.post(
          "https://api.cloudinary.com/v1_1/sourabhvaish/image/upload",
          data
        );
        if (res?.data?.url) {
          formData.image = res?.data?.url;
          console.log(formData);
          // dispatch(createConsultant(formData));
          const data = await createConsultantnoRedux(formData);
          if (data.data) {
            navigate("/consultant");
          }
          if (!data.data) {
            console.log(data.response.data.message);
            // Swal(data.response.data.message, "error");
            Swal({
              title: "Error",
              text: data.response.data.message,
              icon: "error",
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(formData);
  return (
    <>
      {!loading && (
        <div>
          <div
            className="profile-nav-bg"
            style={{ backgroundImage: "url(" + BgProfile + ")" }}
          ></div>

          <Card
            className="card-profile-head"
            bodyStyle={{ display: "none" }}
            title={
              <Row justify="space-between" align="middle" gutter={[24, 0]}>
                <Col span={24} md={12} className="col-info">
                  <Avatar.Group>
                    <div className="avatar-info">
                      <h4 className="font-semibold m-0">{activeUser?.name}</h4>
                      <p>{activeUser?.data?.role}</p>
                      {activeUser?.data?.email}
                    </div>
                  </Avatar.Group>
                </Col>
                <Col
                  span={24}
                  md={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                ></Col>
              </Row>
            }
          ></Card>
          <Form
            // onSubmit={handelsubmit}
            onFinish={handelsubmit}
          >
            <Row gutter={[24, 0]}>
              <Col span={24} md={8} className="">
                <Card
                  bordered={false}
                  className="header-solid "
                  title={<h6 className="font-semibold m-0">Form </h6>}
                >
                  <ul className="list settings-list">
                    <li>
                      <h6 className="list-header text-sm text-muted">
                        ACCOUNT
                      </h6>
                    </li>
                    {/* <li>
                      {user?.blocked === true ? (
                        <Switch
                          name="block"
                          onChange={blockhandelchange}
                          defaultChecked
                        />
                      ) : (
                        <Switch onChange={blockhandelchange} />
                      )}

                      <span>Block Account</span>
                    </li> */}
                    <div className="" />

                    <Descriptions title="">
                      <Descriptions.Item label="Price Per Minute" span={3}>
                        <Input
                          required={true}
                          name="pricePerMinute"
                          type="number"
                          onChange={(e) => {
                            setformData({
                              ...formData,
                              ["pricePerMinute"]: Number(e.target.value),
                            });
                          }}
                          placeholder="Price"
                          style={{ width: "90px", margin: "0px 0px 0px 20px" }}
                        ></Input>
                      </Descriptions.Item>

                      <Descriptions.Item label="Comunication Type" span={3}>
                        <Checkbox
                          required={true}
                          value="audio"
                          onChange={(e) => {
                            let arr = [...formData.communicationType];
                            var myIndex = arr.indexOf(e.target.value);
                            if (e.target.checked) {
                              arr.push(e.target.value);
                              setformData({
                                ...formData,
                                communicationType: arr,
                              });
                            } else {
                              arr.splice(myIndex, 1);
                              setformData({
                                ...formData,
                                communicationType: arr,
                              });
                            }
                          }}
                        >
                          Audio
                        </Checkbox>
                        <Checkbox
                          value="video"
                          onChange={(e) => {
                            let arr = [...formData.communicationType];
                            var myIndex = arr.indexOf(e.target.value);
                            if (e.target.checked) {
                              arr.push(e.target.value);
                              setformData({
                                ...formData,
                                communicationType: arr,
                              });
                            } else {
                              arr.splice(myIndex, 1);
                              setformData({
                                ...formData,
                                communicationType: arr,
                              });
                            }
                          }}
                        >
                          video
                        </Checkbox>
                      </Descriptions.Item>
                      <Descriptions.Item label="Image" span={3}>
                        <Space
                          direction="vertical"
                          style={{
                            width: "100%",
                          }}
                          size="large"
                        >
                          <input
                            required={true}
                            type="file"
                            onChange={(e) => {
                              // imagechnage(e.target.files[0]);
                              setimage(e.target.files[0]);
                            }}
                          />
                        </Space>
                      </Descriptions.Item>
                    </Descriptions>
                  </ul>
                </Card>
              </Col>
              <Col span={24} md={8} className="mb-24">
                <Card bordered={false}>
                  <Descriptions title="Details">
                    <Descriptions.Item label="FirstName" span={3}>
                      <Input
                        required={true}
                        name="firstName"
                        onChange={handelchange}
                      ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="LastName" span={3}>
                      <Input
                        required={true}
                        name="lastName"
                        onChange={handelchange}
                      ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Category" span={3}>
                      <Select
                        showSearch
                        placeholder="Select a person"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        // filterOption={(input, option) =>
                        //   // (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        // }
                      >
                        {data.map((e) => {
                          return <Option value={e?.title}>{e?.title}</Option>;
                        })}
                      </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label="About" span={3}>
                      <Input
                        required={true}
                        name="about"
                        onChange={handelchange}
                      ></Input>
                    </Descriptions.Item>

                    {/* <Descriptions.Item label="About" span={3}>
                      
                      <Input
                        name="about"
                        onChange={handelchange}
                        style={{ width: "390px", margin: "0px 0px 0px 25px" }}
                      ></Input>
                    </Descriptions.Item> */}

                    <Descriptions.Item label="Availability" span={3}>
                      <Input
                        required={true}
                        name="availability"
                        onChange={handelchange}
                      ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone" span={3}>
                      <Input
                        minLength={10}
                        required={true}
                        // value={formData.phone}
                        type="text"
                        accept="number"
                        maxLength={"10"}
                        name="phone"
                        onChange={handelchangephone}
                      ></Input>
                    </Descriptions.Item>
                  </Descriptions>
                  <Button
                    // type="primary"
                    type="primary"
                    htmlType="submit"
                    className="tag-primary"
                    style={{ background: "#A40165", borderColor: "#A40165" }}
                  >
                    Submit
                  </Button>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
}

export default Profile;
