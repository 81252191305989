import React, { useEffect } from "react";
import Main from "../components/layout/ConsultantMain";

import Profile from "../pages/Profile";

import Consultanthistory from "../pages/ConsultantData";
import Usercall from "../pages/Userscall";
import Consultant from "../pages/Consultant";
import ConsultantChatbox from "../pages/ConsultantChatbox";
import ConsultantCreate from "../pages/ConsultantCreate";
import ConsultantProfile from "../pages/ConsultantProfile";
import ConsultantProfiledetails from "../pages/ConsultantProfileDetails";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { SOCKET_URL } from "../utils/api";
import { ChatBoxright } from "../components/ChatBoxright";
import { socketsss } from "../components/layout/consultantSidenav";
import Incomingcall from "../pages/Incomingcall";
import Oncall from "../pages/OnCall";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { incomingCall } from "../redux/consultant/consultantAction";

const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const datas = useSelector((state) => state?.consultant?.incomingCall);
  const params = window.location;
  useEffect(() => {
    socketsss.on("call", (data) => {
      dispatch(incomingCall(data));

      if (params.hash == `#/incomingcall/${data.fromUser}/${data.meetingId}`) {
        console.log("already navigated");
      } else {
        navigate(`/incomingcall/${data.fromUser}/${data.meetingId}`);
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    socketsss.on("endcall", (data) => {
      console.log({ callended: data });
      navigate("/users");
      window.location.reload();
    });
    socketsss.on("callbusy", (data) => {
      console.log("callbusy", data);
      socketsss.emit("busycallend", {
        toUser: data.fromUser,
        fromUser: data.toUser,
      });
      // console.log({ callended: data });
      // navigate("/users");
      // window.location.reload();
    });
    return () => {};
  }, []);

  return (
    <Main>
      <Routes>
        <Route exact path="/users" element={<Consultanthistory />} />
        <Route exact path="/" element={<Consultanthistory />} />
        <Route exact path="/chatsss" element={<ConsultantChatbox />} />
        <Route
          exact
          path="/incomingcall/:id/:meetingid"
          element={<Incomingcall />}
        />
        <Route exact path="/oncall/:id/:meetingid" element={<Oncall />} />
        {/* <Route exact path="/userslist" element={<Usercall />} /> */}

        {/* <Route exact path="/" element={<Users />} />
        <Route exact path="/profile/:id" element={<Profile />} />

        <Route exact path="/consultant" element={<Consultant />} />
        <Route
          exact
          path="/consultantcreate/:id"
          element={<ConsultantCreate />}
        />
        <Route
          exact
          path="/consultantprofile"
          element={<ConsultantProfile />}
        />
        <Route
          exact
          path="/consultantdetails/:id"
          element={<ConsultantProfiledetails />}
        /> */}
      </Routes>
    </Main>
  );
};

export default Admin;
