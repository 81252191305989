import React, { useEffect, useState } from "react";
import { Button, Card, InputNumber, List } from "antd";
import {
  createReferalcode,
  deleteReferalcode,
  getAllReferalCode,
} from "../redux/adminLogin/adminAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export default function Referalcode() {
  const [atemps, setatemps] = useState(20);
  const dispatch = useDispatch();
  const { referalCode } = useSelector((state) => state.userLogin);

  const onChange = (value) => {
    setatemps(value);
  };

  const handelSubmit = () => {
    createReferalcode({ atemps }).then((e) => {
      window.location.reload();
    });
  };
  useEffect(() => {
    dispatch(getAllReferalCode());

    return () => {};
  }, []);
  const data = [
    {
      title: "Title 1",
    },
    {
      title: "Title 2",
    },
    {
      title: "Title 3",
    },
    {
      title: "Title 4",
    },
    {
      title: "Title 4",
    },
  ];
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          // width: "50%",
          alignItems: "center",
        }}>
        <div style={{ width: "50%" }}>
          <InputNumber
            style={{ width: "50%" }}
            min={1}
            max={2000}
            defaultValue={atemps}
            onChange={onChange}
          />
          <Button
            onClick={handelSubmit}
            type="primary"
            className="tag-primary"
            style={{ background: "#A40165", borderColor: "#A40165" }}>
            Create
          </Button>
        </div>
      </div>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={referalCode}
        renderItem={(item) => (
          <>
            <Card
              style={{ margin: 16 }}
              type="inner"
              title={"Atemps " + item.atemps}
              extra={
                <div
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => {
                    console.log("delete");
                    deleteReferalcode(item._id).then((e) => {
                      window.location.reload();
                    });
                  }}>
                  Delete
                </div>
              }>
              Referal Code:- <b>{item.code}</b>
            </Card>
          </>
        )}
      />
    </>
  );
}
