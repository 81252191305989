import {
  GET_CONSULTANTLIST_REQUEST,
  GET_CONSULTANTLIST_SUCCESS,
  GET_CONSULTANTLIST_FAIL,
  GET_CONSULTANTDEATILS_REQUEST,
  GET_CONSULTANTDEATILS_SUCCESS,
  GET_CONSULTANTDEATILS_FAIL,
  GET_CONSULTANTUPDATE_REQUEST,
  GET_CONSULTANTUPDATE_SUCCESS,
  GET_CONSULTANTUPDATE_FAIL,
  GET_CONSULTANTCREATE_REQUEST,
  GET_CONSULTANTCREATE_SUCCESS,
  GET_CONSULTANTCREATE_FAIL,
  INCOMING_CALL,
  CLEAR_INCOMING_CALL,
} from "./consultantConstant";
import api from "../../utils/api";

export const getConsultantList = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CONSULTANTLIST_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    const { data } = await api.post("/consultant", config);

    dispatch({ type: GET_CONSULTANTLIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CONSULTANTLIST_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const sendConsultantMessage = async (formData) => {
  // console.log({ formData });
  try {
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    const { data } = await api.post(
      `/consultantmessage/consultant`,
      formData,
      config
    );
    // console.log(data)
    return data;
    // dispatch({ type: CHAT_MESSAGE_ADD_HOME, payload: formData });
  } catch (error) {
    console.log(error);
  }
  // chatmessagess();
};
export const createConsultant = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_CONSULTANTCREATE_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    console.log({ formData });
    const data = await api.post(`/consultant/create`, formData, config);
    console.log(data);
    dispatch({ type: GET_CONSULTANTCREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CONSULTANTCREATE_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createConsultantnoRedux = async (formData) => {
  try {
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    console.log({ formData });
    const data = await api.post(`/consultant/create`, formData, config);
    console.log(data);
    return data;
  } catch (error) {
    return error;
  }
};

export const getConsultantbyid = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_CONSULTANTDEATILS_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    const { data } = await api.get(`/consultant/${id}`, config);
    console.log(id);
    dispatch({ type: GET_CONSULTANTDEATILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CONSULTANTDEATILS_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateConsultant = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_CONSULTANTUPDATE_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    const { data } = await api.put(`/consultant/${id}`, formData, config);
    console.log(id);
    dispatch({ type: GET_CONSULTANTUPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CONSULTANTUPDATE_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendPushNotification = (formData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    const { data } = await api.post(
      `/notification/pushnotification`,
      formData,
      config
    );
  } catch (error) {}
};

export const incomingCall = (data) => async (dispatch, getState) => {
  dispatch({ type: INCOMING_CALL, payload: data });
};


export const ClearincomingCall = (data) => async (dispatch, getState) => {
  dispatch({ type: CLEAR_INCOMING_CALL, payload: data });
};
