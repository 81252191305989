import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Switch,
  Upload,
  Form,
  Checkbox,
  Space,
} from "antd";

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/face-1.jpg";
import convesionImg from "../assets/images/face-3.jpg";
import convesionImg2 from "../assets/images/face-4.jpg";
import convesionImg3 from "../assets/images/face-5.jpeg";
import { Input } from "antd";
import project1 from "../assets/images/home-decor-1.jpeg";
import project2 from "../assets/images/home-decor-2.jpeg";
import project3 from "../assets/images/home-decor-3.jpeg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserforAdmin,
  getUserDeatilsbyId,
} from "../redux/usersList/userAction";
import {
  getUserDeatils,
  updateUserDetails,
} from "../redux/usersList/userAction";
import { createConsultant } from "../redux/consultant/consultantAction";
import { InfoOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
function Profile(props) {
  const { activeUser, loading } = useSelector((state) => state.user);
  const cretedform = useSelector(
    (state) => state.consultant?.consultantCreated?.statusText
  );
  const navigate = useNavigate();
  if (cretedform == "Created") {
    navigate("/consultant");
  }
  const user = useSelector((state) => state.user?.activeUser?.data);
  // console.log("user", user);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDeatilsbyId(id));
    // dispatch(createConsultant());

    // return () => {};
  }, []);
  const [formData, setformData] = useState({
    email: "",
    password: "",
    phone: "",
  });

  // console.log(formData);

  const handelchange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };

  const blockhandelchange = (e) => {
    dispatch(updateUserDetails(id, { blocked: e }));
  };

  const { phone, about, availability, pricePerMinute, comunicationType } =
    formData;

  const [image, setimage] = useState();

  console.log({ formData });
  const imagechnage = (e) => {};
  const handelsubmit = async (e) => {
    const data = {
      email: formData.email,
      password: formData.password,
      phone: Number(formData.password),
    };
    const a = await createUserforAdmin(data);
    console.log(a);
    if (a.email && a.password && a.phone) {
     
      Swal.fire("Good job!", "User created successfully!", "success");
    } else {
      Swal.fire(
        "Good job!",
        "Please use diffferent Email and Password!",
        "error"
      );
    }
  };
  // console.log(formData);
  return (
    <>
      {!loading && (
        <div>
          <div
            className="profile-nav-bg"
            style={{ backgroundImage: "url(" + BgProfile + ")" }}
          ></div>

          <Card
            className="card-profile-head"
            bodyStyle={{ display: "none" }}
            title={
              <Row justify="space-between" align="middle" gutter={[24, 0]}>
                <Col span={24} md={12} className="col-info">
                  <Avatar.Group>
                    <div className="avatar-info">
                      <h4 className="font-semibold m-0">{activeUser?.name}</h4>
                      <h4>User Create</h4>
                      {activeUser?.data?.email}
                    </div>
                  </Avatar.Group>
                </Col>
                <Col
                  span={24}
                  md={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                ></Col>
              </Row>
            }
          ></Card>
          <Form
          // onSubmit={handelsubmit}
          // onFinish={handelsubmit}
          >
            <Row gutter={[24, 0]}>
              <Col span={24} md={8} className="mb-24">
                <Card bordered={false}>
                  <Descriptions title="Detailsss">
                    <Descriptions.Item label="Email" span={3}>
                      <Input
                        type="email"
                        name="email"
                        onChange={handelchange}
                      ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone" span={3}>
                      <Input
                        type="number"
                        name="phone"
                        onChange={handelchange}
                        // style={{ width: "390px", margin: "0px 0px 0px 25px" }}
                      ></Input>
                    </Descriptions.Item>
                    <Descriptions.Item label="Password" span={3}>
                      <Input name="password" onChange={handelchange}></Input>
                    </Descriptions.Item>

                  </Descriptions>
                  <Button
                    onClick={handelsubmit}
                    // type="primary"
                    type="primary"
                    htmlType="submit"
                    className="tag-primary"
                    style={{ background: "#A40165", borderColor: "#A40165" }}
                  >
                    Submit
                  </Button>
                </Card>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
}

export default Profile;
