import { Row, Col, Card, Table, Button, Avatar, Typography, Input } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import UserLinesChart from "../components/chart/UserLinesChart";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserList } from "../redux/usersList/userAction";
import { useSelector } from "react-redux";
import moment from "moment";

import UsersEChart from "../components/chart/UserEchart";
import {
  getAnalytics,
  getUserAnalytics,
} from "../redux/analytics/AnalytisAction";
import { getConsultantList } from "../redux/consultant/consultantAction";
const { Title } = Typography;
// table code start
const columns = [
  {
    title: "USER",
    dataIndex: "name",
    key: "name",
    width: "32%",
  },
  {
    title: "PHONE",
    dataIndex: "PHONE",
    key: "PHONE",
  },
  {
    title: "AVALIBILITY",
    key: "avalibility",
    dataIndex: "avalibility",
  },
  {
    title: "PRICE PER MINUTE",
    key: "priceperminute",
    dataIndex: "priceperminute",
  },
  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
  },
  //   {
  //     title: "SUBSCRIPTION",
  //     key: "subscription",
  //     dataIndex: "subscription",
  //   },
  {
    title: "CREATED AT",
    key: "Created",
    dataIndex: "Created",
  },
];

const ConsultantProfile = () => {
  const datas = useSelector((state) => state.consultant.consultantList);

  let datavalue = datas?.map((w, i) => {
    return {
      key: i,
      name: (
        <>
          <Avatar.Group>
            <Avatar
              className="shape-avatar"
              shape="square"
              size={40}
              src={w?.image}
            ></Avatar>
            <div className="avatar-info">
              {/* <Title level={5}>{w?.about}</Title> */}
              <p>{w?.user?.email}</p>
            </div>
          </Avatar.Group>{" "}
        </>
      ),
      PHONE: (
        <>
          <div className="author-info">
            <Title level={5}>{w?.phone}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),
      avalibility: (
        <>
          <div className="author-info">
            <Title level={5}>{w?.availability}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),
      status: (
        <>
          {w?.user?.blocked ? (
            <Button type="danger" className="danger">
              Blocked
            </Button>
          ) : (
            <Button
              // type="primary"
              type="primary"
              className="tag-primary"
              style={{ background: "#A40165", borderColor: "#A40165" }}
            >
              Active
            </Button>
          )}
        </>
      ),
      priceperminute: (
        <>
          <div className="ant-employed">
            <Title level={5}>{w?.pricePerMinute + "Rs"}</Title>
          </div>
        </>
      ),
      //   subscription: (
      //     <>
      //       <div className="ant-employed">
      //         <span>Ends in 2 month</span>
      //       </div>
      //     </>
      //   ),
      Created: (
        <>
          <div className="ant-employed">
            <span>{moment(w?.createdAt).format("MMM Do YY")}</span>
            <Link to={`/consultantdetails/${w?._id}`}>Edit</Link>
          </div>
        </>
      ),
    };
  });
  const data = useSelector((state) => state?.analytics?.analyticsData);
  const monthdata = [];
  const date = new Date();
  const year = moment(date).format("YY");
  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  for (let i = 0; i < month.length; i++) {
    let com = data.filter(function (company) {
      if (company.createdAt === `${month[i]} ${year}`) {
        return true;
      }
    });
    monthdata.push(com.length);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getUserList());
    // dispatch(getUserAnalytics());
    dispatch(getConsultantList());
    dispatch(getAnalytics());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <div className="layout-content">
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <UsersEChart month={monthdata} total={"Total Consultant"} />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
                  <Card bordered={false} className="criclebox h-full">
                    <UserLinesChart
                      month={monthdata}
                      total={"Total Consultant"}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Consultant Table"
              extra={
                <Input
                  className="header-search"
                  placeholder="Type here..."
                  prefix={<SearchOutlined />}
                />
              }
            >
              {" "}
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={datavalue}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ConsultantProfile;
