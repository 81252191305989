import {
  GET_ANALYTICS_REQUEST,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAIL,
  GET_USERANALYTICS_REQUEST,
  GET_USERANALYTICS_SUCCESS,
  GET_USERANALYTICS_FAIL,
  GET_USERPROFILEANALYTICS_REQUEST,
  GET_USERPROFILEANALYTICS_SUCCESS,
  GET_USERPROFILEANALYTICS_FAIL,
} from "./AnalyticsConstant";
import api from "../../utils/api";
import moment from "moment";
export const getAnalytics = (url) => async (dispatch) => {
  try {
    dispatch({ type: GET_ANALYTICS_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    const { data } = await api.post(`/consultant`, config);
console.log({data})
    const analytics = data?.map((e) => {
      return { createdAt: moment(e.createdAt).format("MMM YY") };
    });

    dispatch({ type: GET_ANALYTICS_SUCCESS, payload: analytics });
  } catch (error) {
    dispatch({
      type: GET_ANALYTICS_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserAnalytics = () => async (dispatch) => {
  try {
    dispatch({ type: GET_USERANALYTICS_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    const { data } = await api.get(`/user`, config);

    const analytics = data.map((e) => {
      return { createdAt: moment(e.createdAt).format("MMM YY") };
    });

    dispatch({ type: GET_USERANALYTICS_SUCCESS, payload: analytics });
  } catch (error) {
    dispatch({
      type: GET_USERANALYTICS_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserProfileAnalytics = () => async (dispatch) => {
  try {
    dispatch({ type: GET_USERPROFILEANALYTICS_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };

    const { data } = await api.get(`/profile`, config);

    const analytics = data.map((e) => {
      return { createdAt: moment(e.createdAt).format("MMM YY") };
    });

    dispatch({ type: GET_USERPROFILEANALYTICS_SUCCESS, payload: analytics });
  } catch (error) {
    dispatch({
      type: GET_USERPROFILEANALYTICS_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
