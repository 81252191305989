import "./App.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MeetingProvider,
  MeetingConsumer,
  useMeeting,
  useParticipant,
  Constants,
  usePubSub,
} from "@videosdk.live/react-sdk";
import Hls from "hls.js";
import "./s.css";
import { authToken, createMeeting } from "./APIToken";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router";
import { socketsss } from "../components/layout/consultantSidenav";
import { useSelector } from "react-redux";

function JoinScreen({ getMeetingAndToken, setMode }) {
  const params = useParams();
  console.log(params.meetingid);
  const [meetingId, setMeetingId] = useState(null);
  const onClick = async (mode) => {
    setMode(mode);
    await getMeetingAndToken(params.meetingid);
  };
  useEffect(() => {
    onClick("CONFERENCE");

    return () => {};
  }, []);

  return (
    <div className="container">
      <button onClick={() => onClick("CONFERENCE")}>Create Meeting</button>
      <br />
      <br />
      {" or "}
      <br />
      <br />
      <input
        type="text"
        placeholder="Enter Meeting Id"
        onChange={(e) => {
          setMeetingId(e.target.value);
        }}
      />
      <br />
      <br />
      <button onClick={() => onClick("CONFERENCE")}>Join as Host</button>
      {" | "}
      <button onClick={() => onClick("VIEWER")}>Join as Viewer</button>
    </div>
  );
}

function ParticipantView(props) {
  const micRef = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
    useParticipant(props.participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  return (
    <div key={props.participantId}>
      {/* <p>
        Participant: {displayName} | Webcam: {webcamOn ? "ON" : "OFF"} | Mic:{" "}
        {micOn ? "ON" : "OFF"}
      </p> */}
      <audio ref={micRef} autoPlay muted={isLocal} />
      {webcamOn && (
        <ReactPlayer
          //
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          //
          url={videoStream}
          //
          height={props.height}
          width={props.width}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        />
      )}
    </div>
  );
}

function TemplateManager() {
  const [bgColor, setBgColor] = useState(null);
  const [viewerMessage, setViewerMessage] = useState(null);

  const { publish: publishBgColor } = usePubSub("CHANGE_BACKGROUND");
  const { publish: publishViewerMessage } = usePubSub("VIEWER_MESSAGE");

  function handleChangeLivestreamBackground() {
    publishBgColor(bgColor, { persist: true });
    setBgColor(null);
  }

  function handleViewerMessage() {
    publishViewerMessage(viewerMessage, { persist: true });
    setViewerMessage(null);
  }

  return (
    <div>
      <div>
        <input
          type="text"
          placeholder="Enter Background Color"
          onChange={(e) => {
            setBgColor(e.target.value);
          }}
          value={bgColor}
        />
        <button onClick={handleChangeLivestreamBackground}>
          Change Livestream Background
        </button>
      </div>
      <div>
        <input
          type="text"
          placeholder="Enter Message for Viewers"
          onChange={(e) => {
            setViewerMessage(e.target.value);
          }}
        />
        <button onClick={handleViewerMessage}>Notify Viewers</button>
      </div>
    </div>
  );
}

function Controls() {
  const { leave, toggleMic, toggleWebcam } = useMeeting();
  const params = useParams();
  const navigate = useNavigate();
  const handel = () => {
    const ConsultantProfileData = JSON.parse(
      localStorage.getItem("Consultantprofile")
    );
    leave();
    socketsss.emit("endcall", {
      toUser: params.id,
      fromUser: ConsultantProfileData._id,
    });
    socketsss.emit("endcalls", {
      toUser: ConsultantProfileData._id,
      fromUser: params.id,
    });
    setTimeout(() => {
      navigate("/users");
    }, 500);
  };
  const datas = useSelector((state) => state?.consultant?.incomingCall);
  console.log({ datas });
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}>
      {datas.type == "audio" && (
        <div style={{ fontSize: "20px", margin: "15px 0px" }}>
          Call in Progress
        </div>
      )}
      <div>
        <button
          id="rejectButton"
          onClick={() => {
            handel();
          }}>
          Leave
        </button>

        <button id="toggleMic" onClick={() => toggleMic()}>
          toggleMic
        </button>
        {datas.type == "video" && (
          <button id="toggleWebCam" onClick={() => toggleWebcam()}>
            toggleWebcam
          </button>
        )}
      </div>
    </div>
  );
}

function SpeakerView() {
  const { participants, hlsState } = useMeeting();
  const speakers = [...participants.values()].filter((participant) => {
    return participant.mode == Constants.modes.CONFERENCE;
  });
  const datas = useSelector((state) => state?.consultant?.incomingCall);
  console.log({ datas });
  return (
    <div>
      <Controls />
      <br />
      {/* {speakers.map((participant,index) => (
        <ParticipantView index={index} participantId={participant.id} key={participant.id} />
        ))} */}
      {datas.type == "video" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {/* <div > */}
          <ParticipantView
            height={"300px"}
            width={"300px"}
            participantId={speakers[1]?.id}
            key={speakers[1]?.id}
          />
          {/* </div> */}
          {/* <div style={{ position: "absolute", zIndex: 1 ,bottom:50 }}> */}
          <ParticipantView
            height={"200px"}
            width={"200px"}
            participantId={speakers[0]?.id}
            key={speakers[0]?.id}
          />
          {/* </div> */}
        </div>
      )}
      {/* <ViewerList /> */}
    </div>
  );
}

function ViewerView() {
  // States to store downstream url and current HLS state
  const playerRef = useRef(null);
  const { hlsUrls, hlsState } = useMeeting();

  useEffect(() => {
    if (hlsUrls.downstreamUrl && hlsState == "HLS_PLAYABLE") {
      if (Hls.isSupported()) {
        const hls = new Hls({
          capLevelToPlayerSize: true,
          maxLoadingDelay: 4,
          minAutoBitrate: 0,
          autoStartLoad: true,
          defaultAudioCodec: "mp4a.40.2",
        });

        let player = document.querySelector("#hlsPlayer");

        hls.loadSource(hlsUrls.downstreamUrl);
        hls.attachMedia(player);
      } else {
        if (typeof playerRef.current?.play === "function") {
          playerRef.current.src = hlsUrls.downstreamUrl;
          playerRef.current.play();
        }
      }
    }
  }, [hlsUrls, hlsState]);

  return (
    <div>
      {hlsState != "HLS_PLAYABLE" ? (
        <div>
          <p>HLS has not started yet or is stopped</p>
        </div>
      ) : (
        hlsState == "HLS_PLAYABLE" && (
          <div>
            <video
              ref={playerRef}
              id="hlsPlayer"
              autoPlay={true}
              controls
              style={{ width: "100%", height: "100%" }}
              playsinline
              playsInline
              muted={true}
              playing
              onError={(err) => {
                console.log(err, "hls video error");
              }}></video>
          </div>
        )
      )}
    </div>
  );
}

function Container(props) {
  const [joined, setJoined] = useState(null);
  const { join, localParticipant, changeMode } = useMeeting();
  const mMeeting = useMeeting({
    onMeetingJoined: () => {
      if (mMeetingRef.current.localParticipant.mode == "CONFERENCE") {
        mMeetingRef.current.localParticipant.pin();
      }
      setJoined("JOINED");
    },
    onMeetingLeft: () => {
      props.onMeetingLeave();
    },
    onParticipantModeChanged: (data) => {
      const localParticipant = mMeetingRef.current.localParticipant;
      if (data.participantId == localParticipant.id) {
        if (data.mode == Constants.modes.CONFERENCE) {
          localParticipant.pin();
        } else {
          localParticipant.unpin();
        }
      }
    },
    onError: (error) => {
      alert(error.message);
    },
    onHlsStateChanged: (data) => {
      console.log("HLS State Changed", data);
    },
  });
  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };
  useEffect(() => {
    joinMeeting();

    return () => {};
  }, []);

  const mMeetingRef = useRef(mMeeting);
  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  const [joinLivestreamRequest, setJoinLivestreamRequest] = useState();

  const pubsub = usePubSub(`CHANGE_MODE_${localParticipant?.id}`, {
    onMessageReceived: (pubSubMessage) => {
      setJoinLivestreamRequest(pubSubMessage);
    },
  });
  const navigate = useNavigate();

  const datas = useSelector((state) => state?.consultant?.incomingCall);
  console.log({ datas });
  useEffect(() => {
    if (!datas) {
      navigate("/users");
    }

    return () => {};
  }, [datas]);

  return (
    <div
      style={{
        height: "100%",
        weight: "700",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="container">
      {/* <h3>Meeting Id: {props.meetingId}</h3> */}
      {joined && joined == "JOINED" ? (
        mMeeting.localParticipant.mode == Constants.modes.CONFERENCE ? (
          <SpeakerView />
        ) : mMeeting.localParticipant.mode == Constants.modes.VIEWER ? (
          <>
            {joinLivestreamRequest && (
              <div>
                {joinLivestreamRequest.senderName} requested you to join
                Livestream
                <button
                  onClick={() => {
                    changeMode(joinLivestreamRequest.message);
                    setJoinLivestreamRequest(null);
                  }}>
                  Accept
                </button>
                <button
                  onClick={() => {
                    setJoinLivestreamRequest(null);
                  }}>
                  Reject
                </button>
              </div>
            )}
            <ViewerView />
          </>
        ) : null
      ) : joined && joined == "JOINING" ? (
        <div>
          {/* <p>Joining the meeting...</p> */}
          <button style={{ height: "40px" }} onClick={joinMeeting}>
            Join
          </button>
        </div>
      ) : (
        <button onClick={joinMeeting}>Join</button>
      )}
    </div>
  );
}

function Oncall() {
  const [meetingId, setMeetingId] = useState(null);
  const [mode, setMode] = useState("CONFERENCE");
  const getMeetingAndToken = async (id) => {
    const meetingId =
      id == null ? await createMeeting({ token: authToken }) : id;
    setMeetingId(meetingId);
  };

  const onMeetingLeave = () => {
    setMeetingId(null);
  };
  const params = useParams();
  const onClick = async (mode) => {
    setMode(mode);
    await getMeetingAndToken(params.meetingid);
  };

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(true);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        // Show a custom message when the user tries to leave the page
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    const ConsultantProfileData = JSON.parse(
      localStorage.getItem("Consultantprofile")
    );
    // Handle the user's choice when they click the "reload" button
    window.addEventListener("unload", () => {
      console.log("sssssssssssssss");
      socketsss.emit("endcall", {
        toUser: params.id,
        fromUser: ConsultantProfileData._id,
      });
      socketsss.emit("endcalls", {
        toUser: ConsultantProfileData._id,
        fromUser: params.id,
      });
      // You can add your custom logic here to perform a specific task.
      // For example, save data or show a message before the page reloads.
      // Note that the options for custom actions are limited and may vary between browsers.
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);
  return authToken && meetingId ? (
    <MeetingProvider
      config={{
        meetingId,
        micEnabled: true,
        webcamEnabled: true,
        name: "C.V. Raman",
        mode: mode,
      }}
      token={authToken}>
      <MeetingConsumer>
        {() => (
          <Container meetingId={meetingId} onMeetingLeave={onMeetingLeave} />
        )}
      </MeetingConsumer>
    </MeetingProvider>
  ) : (
    <JoinScreen getMeetingAndToken={getMeetingAndToken} setMode={setMode} />
  );
}

export default Oncall;
