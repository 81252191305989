import {
  GET_ADMINLOGIN_REQUEST,
  GET_ADMINLOGIN_SUCCESS,
  GET_ADMINLOGIN_FAIL,
  GET_REFERAL_CODE_REQUEST,
  GET_REFERAL_CODE_SUCCESS,
  GET_REFERAL_CODE_FAIL,
} from "./adminConstant";
import api from "../../utils/api";
import Swal from "sweetalert2";

export const userLoginAction =
  ({ username, password }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ADMINLOGIN_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await api.post(
        "/user/login",
        { username, password },
        config
      );
      //  console.log(data.)
      if (data?.user?.role === "admin") {
        localStorage.setItem(
          "authorization",
          JSON.stringify(`Bearer ${data.access_token}`)
        );
        localStorage.setItem("userAuth", JSON.stringify(data.user));

        window.location.href = "/#/";
        window.location.reload(false);
      } else if (data?.user?.role === "consultant") {
        localStorage.setItem(
          "authorization",
          JSON.stringify(`Bearer ${data.access_token}`)
        );
        localStorage.setItem(
          "Consultantprofile",
          JSON.stringify(data.Consultantprofile)
        );
        localStorage.setItem("userAuth", JSON.stringify(data.user));

        window.location.href = "/#/";
        window.location.reload(false);
      } else {
        // window.location.href = "/signin";
        Swal.fire(
          "Warning!",
          "Your credentials doesn't match with admin or consultant credentials",
          "warning"
        );
      }
      dispatch({ type: GET_ADMINLOGIN_SUCCESS, payload: data });
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_ADMINLOGIN_FAIL,
        payload:
          error.data && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      Swal.fire(
        "Warning!",
        "Your credentials doesn't match with admin or consultant credentials",
        "warning"
      );
    }
  };

export const createReferalcode = async (formData) => {
  try {
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    const { data } = await api.post("/referalcode", formData, config);
    return data
    console.log(data);
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllReferalCode = () => async (dispatch) => {
  try {
    dispatch({ type: GET_REFERAL_CODE_REQUEST });
    const token = localStorage.getItem("authorization");

    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    const { data } = await api.get("/referalcode", config);

    dispatch({ type: GET_REFERAL_CODE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_REFERAL_CODE_FAIL,
      payload:
        error.data && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    // Swal.fire(
    //   "Warning!",
    //   "Your credentials doesn't match with admin or consultant credentials",
    //   "warning"
    // );
    console.log(error);
  }
};

export const deleteReferalcode = async (params) => {
  try {
    const token = localStorage.getItem("authorization");
    
    const config = {
      headers: {
        "Content-Types": "application/json",
        Authorization: JSON.parse(token),
      },
    };
    
    console.log(config);
    const { data } = await api.delete(`/referalcode/${params}`,  config);
    console.log(data);
    return data
    return data;
  } catch (error) {
    return error;
  }
};
