import React, { useEffect } from "react";
import { socketsss } from "./layout/consultantSidenav";

export default function Chatcard({
  e,
  setindex,
  index,
  ind,
  isconsultant,
  setlastindex,
  last,
}) {
  const userData = JSON.parse(localStorage.getItem("Consultantprofile"));
  // const d = e.chatmsg.filter((ele) => {
  //   return ele.userid != userData._id;
  // });
  // console.log({ d });
  useEffect(() => {
    // socketsss.emit("join-room", userData._id + e._id);
// console.log(userData._id + e._id);
    return () => {};
  }, []);

  return (
    <div
      style={{ border: `${index == ind ? "2px solid #A40165" : ""}` }}
      onClick={(s) => {
        setlastindex(last);
        setindex(index);
        localStorage.setItem("index", index);
      }}
      className="usercard"
    >
      <div
        className="userimg"
        style={{
          backgroundImage: `url(${e.profilePic?.url})`,
        }}
      />
      <div className="username">{e?.firstName}</div>
      {e.chatlength.length > 0 && (
        <div className="chatlength">{e.chatlength.length}</div>
      )}
    </div>
  );
}
