import {
  GET_CONSULTANTLIST_REQUEST,
  GET_CONSULTANTLIST_SUCCESS,
  GET_CONSULTANTLIST_FAIL,
  GET_CONSULTANTDEATILS_REQUEST,
  GET_CONSULTANTDEATILS_SUCCESS,
  GET_CONSULTANTDEATILS_FAIL,
  GET_CONSULTANTUPDATE_REQUEST,
  GET_CONSULTANTUPDATE_SUCCESS,
  GET_CONSULTANTUPDATE_FAIL,
  GET_CONSULTANTCREATE_REQUEST,
  GET_CONSULTANTCREATE_SUCCESS,
  GET_CONSULTANTCREATE_FAIL,
  INCOMING_CALL,
  CLEAR_INCOMING_CALL,
} from "./consultantConstant";

const initialState = {
  loading: false,
  activeConsultant: [],
  consultantList: [],
  consultantupdate: [],
  consultantCreated: null,
  error: null,
  incomingCall: null,
};

export const consultantReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CONSULTANTLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CONSULTANTLIST_SUCCESS:
      return {
        ...state,
        consultantList: payload,
        consultantCreated: [],
        consultantupdate: [],

        loading: false,
      };

    case GET_CONSULTANTLIST_FAIL:
      return {
        ...state,
        loading: false,
        consultantList: [],
        consultantCreated: [],
        consultantupdate: [],
      };

    case GET_CONSULTANTDEATILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CONSULTANTDEATILS_SUCCESS:
      return {
        ...state,
        activeConsultant: payload,
        loading: false,
      };
    case GET_CONSULTANTDEATILS_FAIL:
      return {
        ...state,
        loading: false,
        consultantList: [],
      };
    case GET_CONSULTANTUPDATE_REQUEST:
      return {
        ...state,

        loading: true,
      };

    case GET_CONSULTANTUPDATE_SUCCESS:
      return {
        ...state,
        consultantupdate: payload,

        loading: false,
      };
    case GET_CONSULTANTUPDATE_FAIL:
      return {
        ...state,
        consultantupdate: [],

        loading: false,
      };
    case GET_CONSULTANTCREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_CONSULTANTCREATE_SUCCESS:
      return {
        ...state,
        consultantCreated: payload,
        loading: false,
      };
    case GET_CONSULTANTCREATE_FAIL:
      return {
        ...state,
        loading: false,
        consultantCreated: [],
      };
    case INCOMING_CALL:
      return {
        ...state,
        incomingCall: payload,
      };
    case CLEAR_INCOMING_CALL:
      return {
        ...state,
        incomingCall: null,
      };
    default:
      return state;
  }
};
