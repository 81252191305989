import { Row, Col, Card, Table, Button, Avatar, Typography, Input } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import UserLinesChart from "../components/chart/UserLinesChart";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserList, getUserLists } from "../redux/usersList/userAction";
import { getConsultantList } from "../redux/consultant/consultantAction";
import { useSelector } from "react-redux";
import moment from "moment";

import UsersEChart from "../components/chart/UserEchart";
import { getUserAnalytics } from "../redux/analytics/AnalytisAction";
const { Title } = Typography;
// table code start
const columns = [
  {
    title: "EMAIL",
    dataIndex: "EMAIL",
    key: "EMAIL",
  },
  {
    title: "PHONE",
    dataIndex: "PHONE",
    key: "PHONE",
  },

  {
    title: "STATUS",
    key: "status",
    dataIndex: "status",
  },
  // {
  //   title: "SUBSCRIPTION",
  //   key: "subscription",
  //   dataIndex: "subscription",
  // },
  {
    title: "CREATED AT",
    key: "Created",
    dataIndex: "Created",
  },
];

const Users = () => {
  const datas = useSelector((state) => state.user);
  // // const s=
  // console.log(datas);
  let datavalue = datas?.userList.map((w, i) => {
    return {
      key: i,

      EMAIL: (
        <>
          <div className="author-info">
            <Title level={5}>{w.email}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),
      PHONE: (
        <>
          <div className="author-info">
            <Title level={5}>{w.phone}</Title>
            {/* <p>Organization</p> */}
          </div>
        </>
      ),

      status: (
        <>
          {w.blocked ? (
            <Button type="danger" className="danger">
              Blocked
            </Button>
          ) : (
            <Button
              // type="primary"
              type="primary"
              className="tag-primary"
              style={{ background: "#A40165", borderColor: "#A40165" }}
            >
              Active
            </Button>
          )}
        </>
      ),
      // subscription: (
      //   <>
      //     <div className="ant-employed">
      //       <span>Ends in 2 month</span>
      //     </div>
      //   </>
      // ),
      Created: (
        <>
          <div className="ant-employed">
            <span>{moment(w.createdAt).format("MMM Do YY")}</span>
            <Link to={`/consultantcreate/${w._id}`}>Create</Link>
          </div>
        </>
      ),
    };
  });
  const data = useSelector((state) => state?.analytics?.UseranalyticsData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserLists());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Users Table"
              extra={
                <Input
                  className="header-search"
                  placeholder="Type here..."
                  prefix={<SearchOutlined />}
                />
              }
            >
              {" "}
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={datavalue}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Users;
