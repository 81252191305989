import { Navigate } from "react-router-dom";

export default function PrivateRoute({ children, ...props }) {
  const token = JSON.parse(localStorage.getItem("userAuth"))?.role;
  // console.log(token);
  if (token === "admin") {
    return children;
  }
  if (token == "consultant") {
    return children;
  }
  return <Navigate to="/signin" />;
}
